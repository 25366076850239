import { createEffect, createEvent, createStore, combine } from 'effector';

export const $schedule = createStore({});
export const getScheduleEvent = createEvent();
export const getScheduleFx = createEffect();


export const $scheduleEvents = $schedule.map((scedule) => scedule.events || []);
export const $scheduleFilter = createStore(null);
export const setScheduleFilter = createEvent();

export const $scheduleData = combine($scheduleEvents, (events) => events)
