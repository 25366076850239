import React from 'react';
import { cx } from '@emotion/css';
import { useStore } from 'effector-react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { $mainData } from '../../effector/main';
import { Layout } from '../../components/Layout';
import { styles } from './About.styles';

export const About = () => {
  const data = useStore($mainData);

  const cards = data?.history?.cards || [];

  return (
    <Layout>
      <div className={styles.title}>о&nbsp;кинотеатре</div>
      {cards.map((item, index) => (
        <div className={cx(styles.infoRow, { [styles.infoRowReverse]: index % 2})}>
          <div className={styles.imageWrap}>
            {item?.media?.item?.srcSet && <img className={styles.infoRowImage} srcSet={item.media.item.srcSet} alt="" />}
          </div>
          <div className={styles.infoRowText}>
            <div className={styles.infoRowTextTitle}>{item.caption}</div>
            <div className={styles.infoRowTextDescription}>
              {item.description}
            </div>
            {item.label && <a href={item.url} className={cx(styles.infoRowTextLink, styles.mobilePadding)}>{item.label} <ArrowRightOutlined /></a>}
          </div>
        </div>
      ))}
    </Layout>
  )
}
