import React from 'react';
import { cx } from '@emotion/css';
import { CINEMA_ID } from '../../variables';
import { styles } from './BuyBtn.styles';


export const DISABLED_FILM = 82075684;
export const DISABLED_FILM_URL = 'https://tinkoffbank.onelink.me/1923863684/jp8mm824';

export const BuyBtn = ({ isSaleAvailable, ramblerAfishaId, className, buyText = 'КУПИТЬ БИЛЕТЫ' }) => {
  return (
    <button
      onClick={() => {
        if (Number(ramblerAfishaId) === DISABLED_FILM) {
         window.location.href = DISABLED_FILM_URL;
         return;
        }
        window.ticketManager.session(CINEMA_ID, ramblerAfishaId)
      }}
      className={cx(styles.btn, className)}
      disabled={!isSaleAvailable}
    >
      {isSaleAvailable ? buyText : 'SOLD OUT'}
    </button>
  )
}

export const LinkBtn = ({ text, url, className }) => {
  return (
    <a
      href={url}
      target={"_blank"}
      className={cx(styles.btn, className)}
    >
      {text}
    </a>
  )
}
