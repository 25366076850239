import { forward } from 'effector';
import { getFaqEvent, $faqData, getFaqFx, $openedItem, toggleItem } from './index';
import { getFaq } from '../../requests';

forward({
  from: getFaqEvent,
  to: getFaqFx
})

$faqData.on(getFaqFx.doneData, (_, data) => data);

getFaqFx.use(() => {
  return getFaq();
})

$openedItem.on(toggleItem, (_, key) => key).reset([ getFaqEvent ])
