import { createStore, createEvent, createEffect } from 'effector';
import { isMobile } from 'react-device-detect';

export const $mainData = createStore({});
export const getMainDataEvent = createEvent();
export const getMainDataFx = createEffect();

export const $width = createStore(null);
export const setWidth = createEvent();

export const $isMobile = $width.map(width => width <= 320 || isMobile)
export const $isTablet = $width.map(width => width <= 720)
