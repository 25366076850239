import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { styles } from './NearFilmsTable.styles'
import { BuyBtn, LinkBtn } from '../BuyBtn';
import { generateBuyUrl } from '../../utils';

export const NearFilmsTable = (props) => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate('/schedule');
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.subWrapper}>
      {props.items.map((film) => (
        <div key={film.ramblerAfishaId} className={styles.row}>
          <div>{dayjs(film.datetime).utcOffset(film.datetime.slice(-6)).format('DD.MM   ·   HH:mm')}</div>
          <div><Link to={`/movies/${film.event.slug}`}>{film.event.title}</Link><span>{film.event.ageRestriction ? `${film.event.ageRestriction}+` : ''}</span></div>
          <div>{film.event.director}</div>
          <div>
            {film.event.type === 'EVENT' && film.event.buyUrl ? <LinkBtn text="БИЛЕТЫ" url={generateBuyUrl(film.event.buyUrl, film.datetime, film.event.slug)} /> :
            <BuyBtn ramblerAfishaId={film.ramblerAfishaId} isSaleAvailable={film.isSaleAvailable} buyText="БИЛЕТЫ" />}
          </div>
        </div>
      ))}
        {!props.withoutFull && <div onClick={onClick} className={styles.btn}>
          СМОТРЕТЬ ПОЛНОЕ РАСПИСАНИЕ
          <ArrowRightOutlined />
        </div>}
      </div>
    </div>
  )
}
