import React, { useEffect, useRef } from 'react';
import { slide as Menu } from 'react-burger-menu'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { cx } from '@emotion/css';
import { useStore } from 'effector-react';
import TelegramImage from './telegram.png';

import { styles } from './Header.styles';
import { $isMobile, $mainData, getMainDataEvent, setWidth, $isTablet } from '../../effector/main';
import { Partners } from '../Partners';

import headBg from './base.svg';
import headTabletBg from './headTabletBg.svg';
import headerMobileBg from './headerMobileBg.svg';
import buttonsBg from './4buttonsBg.svg';
import {IconTelegram} from "../icons/IconTelegram";
const stylesMenu = {
  bmBurgerButton: {
    position: 'absolute',
    width: '19px',
    height: '16px',
    right: '16px',
    top: '26px'
  },
  bmBurgerBars: {
    background: 'white',
    height: '10%'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    width: '100%',
  },
  bmMenu: {
    background: '#00123F',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em'
  },
  bmItem: {
    display: 'inline-block'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
}
const NavLink = (props) => {
  return (
    <Link to={props.to} className={cx(styles.navItem, props.className)}>
      <div>{props.text}</div>
    </Link>
  )
}

export const Header = () => {
  useEffect(() => {
    getMainDataEvent();
  }, [])
  const ref = useRef(null)
  const navigate = useNavigate();

  const width = ref.current?.getBoundingClientRect()?.width;
  useEffect(() => {
    if (width) {
      setWidth(width);
    }
  }, [width])

  const isMobile = useStore($isMobile);
  const isTablet = useStore($isTablet);
  let bg = null;
  if (isMobile) {
    bg = headerMobileBg;
  } else if (isTablet) {
    bg = headTabletBg;
  } else {
    bg = headBg;
  }

  return (
    <div className={styles.wrapper} ref={ref}>
      {isMobile && <Menu styles={stylesMenu}>
        <Link className={styles.mobileItem} to="/schedule">РАСПИСАНИЕ</Link>
        <Link className={styles.mobileItem} to="/programs">ФИЛЬМЫ</Link>
        <Link className={styles.mobileItem} to="/about">О&nbsp;КИНОТЕАТРЕ</Link>
        <Link className={styles.mobileItem} to="/faq">ГОСТЯМ</Link>
        <Link className={styles.mobileItem} to="/contacts">КОНТАКТЫ</Link>
      </Menu>}
      <div className={styles.head}>
        <img src={bg} alt="head" />
        <div className={cx(styles.title, { [styles.titleMobile]: isMobile})} onClick={() => navigate('/')}>SUMMER CINEMA {isMobile && <br />} BY&nbsp;KION</div>
      </div>
      {!isMobile && <div className={styles.subHead} style={{ backgroundImage: `url(${buttonsBg})` }}>
        <NavLink text="РАСПИСАНИЕ" to="/schedule"/>
        <NavLink text="ФИЛЬМЫ" to="/programs" />
        <NavLink text="О&nbsp;КИНОТЕАТРЕ" to="/about"  />
        <NavLink text="ГОСТЯМ" to="/faq" />
      </div>}
    </div>
  )
}


export const Footer = () => {
  const data = useStore($mainData);
  const isMobile = useStore($isMobile);
  const { pathname } = useLocation();
  const isMobileDetails = pathname.includes('/movies/');
  return (
    <>
      {!isMobileDetails && <Partners items={data?.partners || []} />}
      {!isMobile && <NavLink text="КОНТАКТЫ" to="/contacts" className={cx(styles.footer)} />}
      <div className={styles.socialLink}>
        <a href="https://t.me/summercinema" target="_blank">
          <img src={TelegramImage} alt="Telegram" />
        </a>
      </div>

      <div className={cx(styles.conf, {[styles.confMobile]: isMobile})}>
        <a target='_blank' href="https://docs.google.com/document/d/1Mgsxv8ACvc2ieyCUVFF1YEde1UDB97IEbBOSCm5w_G4/edit">ПОЛОЖЕНИЕ О КОНФИДЕНЦИАЛЬНОСТИ</a>
      </div>
    </>
  )
}
