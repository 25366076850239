import { forward } from 'effector';

import { $mainData, getMainDataEvent, getMainDataFx, $width, setWidth  } from './index';
import { getHomePageData } from '../../requests'

forward({
  from: getMainDataEvent,
  to: getMainDataFx
})

$mainData.on(getMainDataFx.doneData, (_, data) => data)

getMainDataFx.use(() => getHomePageData())


$width.on(setWidth, (_, width) => width);
