import { css } from "@emotion/css";

export const styles = {
  wrapper: css`
    margin-bottom: 32px;
  `,
  head: css`
    position: relative;
    img {
      width: 100%;
      object-fit: contain;
    }
  `,
  title: css`
    position: absolute;
    cursor: pointer;
    font-size: 74px;
    left: 50%;
    top: 50%;
    width: fit-content;
    transform: translate(-50%, -50%);
    @media (max-width: 950px) {
      font-size: 56px;
    }

    @media (max-width: 780px) {
      font-size: 74px;
      text-align: center;
    }

    @media (max-width: 600px) {
      font-size: 56px;
      text-align: center;
    }

    @media (max-width: 450px) {
      font-size: 38px;
      text-align: center;
    }

    @media (max-width: 320px) {
      font-size: 32px;
      text-align: center;
      padding-left: 30px;
      padding-right: 30px;
    }
    
    &:hover {
      color: rgba(255, 0, 106, 1);
    }
  `,
  titleMobile: css`
    text-align: right !important;
    padding-left: 0 !important;
    width: 100% !important;
    padding-right: 36px !important;
  `,
  subHead: css`
    background-size: contain;
    background-repeat: no-repeat;
    height: 140px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    
    @media (max-width: 1300px) {
      height: 127px;
    }
    @media (max-width: 1250px) {
      height: 122px;
    }
    @media (max-width: 1200px) {
      height: 117px;
    }
    @media (max-width: 1150px) {
    }
    @media (max-width: 1100px) {
      height: 107px;
    }
    
    @media (max-width: 1050px) {
      height: 102px;
    }

    @media (max-width: 1000px) {
      height: 97px;
    }
    
    @media (max-width: 950px) {
      height: 92px;
    }

    @media (max-width: 900px) {
      height: 87px;
    }

    @media (max-width: 850px) {
      height: 83px;
    }

    @media (max-width: 800px) {
      height: 77px;
    }
    
    @media (max-width: 750px) {
      height: 72px;
    }
    
    @media (max-width: 700px) {
      height: 68px;
    }

    @media (max-width: 650px) {
      height: 63px;
    }

    @media (max-width: 600px) {
      height: 58px;
    }

    @media (max-width: 550px) {
      height: 54px;
    }

    @media (max-width: 500px) {
      height: 48px;
    }

    @media (max-width: 450px) {
      height: 43px;
    }

    @media (max-width: 400px) {
      height: 38px;
    }

    @media (max-width: 320px) {
      display: none;
    }
  `,
  navItem: css`
    font-size: 36px;
    color: white;
    white-space: nowrap;
    
    div {
      width: 259px;
      text-align: center;
    }
    
    &:hover {
      color: rgba(255, 0, 106, 1);
    }

    @media (max-width: 1150px) {
      font-size: 28px;
      div {
        width: 202px;
      }
    }

    @media (max-width: 1000px) {
      font-size: 24px;
      div {
        width: 173px;
      }
    }

    @media (max-width: 760px) {
      font-size: 16px;
      div {
        width: 116px;
      }
    }

    @media (max-width: 600px) {
      font-size: 14px;
      div {
        width: 105px;
      }
    }

    @media (max-width: 450px) {
      font-size: 12px;
      div {
        width: 87px;
      }
    }
  `,
  mobileItem: css`
    display: block !important;
    color: white;
    font-size: 32px;
    margin-bottom: 36px;
    &:hover {
      color: rgba(255, 0, 106, 1);
    }
  `,
  footer: css`
    div {
      padding-top: 45px;
      padding-bottom: 45px;
      background: linear-gradient(90deg, #00123F 0%, #081B48 18%, #1D3360 48%, #405A88 85%, #4F6B99 100%);
      width: 100%;
    }
    text-align: center;
  `,
  footerMobileDetails: css`
    @media (max-width: 720px) {
      div {
        margin-bottom: 100px;
      }
    }
  `,
  conf: css`
    text-align: center;
    margin-top: 32px;
    margin-bottom: 32px;
    a {
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      text-decoration-line: underline;
      color: #FFFFFF;
      opacity: 0.6;

      &:hover {
        color: rgba(255, 0, 106, 1);
      }
    }
  `,
  confMobile: css`
    margin-bottom: 110px;
  `,
  socialLink: css`
    padding-top: 24px;
    text-align: center;
    img {
      width: 48px;
      height: 48px
    }

    @media (max-width: 320px) {
      img {
        width: 72px;
        height: 72px
      }
    }
  `
}
