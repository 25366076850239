import { forward } from 'effector';

import { $schedule, getScheduleEvent, getScheduleFx, $scheduleFilter, setScheduleFilter } from './';
import { getSchedule } from '../../requests';
import dayjs from 'dayjs';

forward({
  from: [ getScheduleEvent, $scheduleFilter ],
  to: getScheduleFx
})

$schedule.on(getScheduleFx.doneData, (_, data) => {
  return data
});

getScheduleFx.use((data) => {
  let start_date = null;
  let end_date = null;
  if (!data) {
    start_date = dayjs().startOf('year').format('YYYY-MM-DD')
    end_date = dayjs().endOf('year').format('YYYY-MM-DD')
  } else {
    start_date = dayjs().month(data).startOf('month').format('YYYY-MM-DD')
    end_date = dayjs().month(data).endOf('month').format('YYYY-MM-DD')
  }
  return getSchedule(start_date, end_date);
})

$scheduleFilter.on(setScheduleFilter, (_, filter) => filter);
