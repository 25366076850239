import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import dayjs from 'dayjs';
import { Main } from '../../pages/Main';
import { MovieDetails } from '../../pages/MovieDetails';
import { About } from '../../pages/About';
import { Programs } from '../../pages/Programs';
import { ProgramDetails } from '../../pages/ProgramDetails';
import { Schedule } from '../../pages/Schedule';
import { Faq } from '../../pages/Faq';
import { Contacts } from '../../pages/Contacts';
import '../../effector/init';
import 'dayjs/locale/ru';

import isTomorrow from 'dayjs/plugin/isTomorrow';
import isToday from 'dayjs/plugin/isToday';
import { ScrollToTop } from '../ScrollToTop';
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone') // dependent on utc plugin

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isTomorrow)
dayjs.extend(isToday)
dayjs.locale('ru')

export function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route index element={<Main />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/movies/:slug" element={<MovieDetails />} />
          <Route path="/about" element={<About />} />
          {/*<Route path="/programs" element={<Programs />} />*/}
          <Route path="/programs" element={<ProgramDetails />} />
          <Route path="/programs/:slug" element={<ProgramDetails />} />
          <Route path="/contacts" element={<Contacts />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}
