import React from 'react';

import { styles } from './Layout.styles';
import { Footer, Header } from '../Header';
import { Spin } from 'antd';

export const Layout = ({ loading = false, children }) => {
  return (
    <Spin spinning={loading}>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <Header />
          {children}
          <Footer />
        </div>
      </div>
    </Spin>
  )
}
