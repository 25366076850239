import { css } from '@emotion/css';

export const styles = {
  movieInfoRow: css`
    display: flex;
    background-color: rgb(19, 32, 72);
    border-radius: 32px;
    margin-bottom: 16px;
    
    @media(max-width: 720px) {
      background: transparent;
      flex-direction: column-reverse;
      margin-bottom: 0;
    }
  `,
  movieInfoRowImage: css`
    flex-grow: 1;
    max-width: 50%;
    max-height: 400px;
    object-fit: contain;
    border-radius: 32px;

    @media(max-width: 720px) {
      max-width: 100%;
    }
  `,
  movieInfoRowInfo: css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 40px;

    @media(max-width: 720px) {
      padding: 12px;
    }
  `,
  rowBuyBtn: css`
    display: flex;
    @media(max-width: 720px) {
      display: none;
    }
  `,
  movieInfoRowTitle: css`
    font-size: 64px;
    margin-bottom: 24px;
    line-height: 100%;
    
    @media(max-width: 720px) {
      font-size: 28px;
      line-height: 36px;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 8px;
    }
  `,
  movieInfoRowTime: css`
    font-size: 36px;
    text-transform: uppercase;
    margin-bottom: 32px;
    line-height: 100%;
    @media(max-width: 720px) {
      display: none;
    }
  `,
  movieInfoRowLabel: css`
    margin-bottom: 16px;
    font-size: 36px;
    line-height: 100%;
    color: #FFFFFF;
    opacity: 0.6;
    @media(max-width: 720px) {
      font-size: 20px;
      line-height: 110%;
      text-align: center;
      margin-bottom: 16px;
    }
  `,
  movieInfoRowDirector: css`
    margin-bottom: 40px;
    font-size: 28px;
    line-height: 36px;
    
    @media(max-width: 720px) {
      font-size: 20px;
      text-align: center;
      margin-bottom: 8px;
    }
  `,
  movieInfoRowBtn: css`
    display: block;
    height: 40px;
    text-align: center;
    margin-left: 10px;

    @media(max-width: 720px) {
      display: none;
    }
  `,
  movieDescriptionRow: css`
    display: flex;
    margin-bottom: 16px;
    background-color: rgb(19, 32, 72);
    border-radius: 32px;
    
    @media(max-width: 720px) {
      background: transparent;
      flex-direction: column;
      padding: 12px;
      padding-top: 0;
    }
  `,
  movieDescription: css`
    flex-grow: 1;
    max-width: 50%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media(max-width: 720px) {
      padding: 0;
      max-width: 100%;
    }
  `,
  movieTrailer: css`
    flex-grow: 1;
    max-width: 50%;
    video {
      height: 100%;
      border-radius: 32px;
    }

    @media(max-width: 720px) {
      display: none;
    }
  `,
  movieDescriptionTitle: css`
    font-size: 36px;
    line-height: 26px;
    
    @media(max-width: 720px) {
      font-size: 24px;
      line-height: 26px;
      margin-bottom: 12px;
    }
  `,
  movieDescriptionText: css`
    margin-top: 20px;
    opacity: .6;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    
    @media(max-width: 720px) {
      font-size: 20px;
      line-height: 110%;
      margin-top: 10px;
    }
  `,
  additionalBlock: css`
    display: inline-block;
    margin-bottom: 24px;
    width: 100%;

    @media(max-width: 720px) {
      margin-left: 12px;
      margin-right: 12px;
    }
  `,
  additionalItem: css`
    vertical-align: top;
    min-height: 220px;
    display: inline-block;
    position: relative;
    margin: 8px;
    width: calc(50% - 16px);
    padding: 20px 50px;
    padding-left: 100px;
    background: rgba(255, 255, 255, 0.06);
    border-radius: 16px;
    text-align: left;
    img {
      left: 19px;
      top: 12px;
      position: absolute;
      width: 48px;
      height: 48px;
    }

    @media(max-width: 720px) {
      width: calc(100% - 24px);
      margin: 0;
      margin-bottom: 8px;
      padding: 0;
      
      img {
        left: 50%;
        transform: translate(-50%, 0);
      }
    } 
  `,
  additionalItemTitle: css`
    opacity: .6;
    font-size: 32px;
    line-height: 100%;

    @media (max-width: 1000px) {
      font-size: 24px;
    }
    
    @media (max-width: 720px) {
      text-align: center;
      margin-top: 80px;
    }
  `,
  additionalItemText: css`
    margin-top: 20px;
    font-size: 44px;
    line-height: 100%;
    
    @media (max-width: 1000px) {
      font-size: 32px;
    }

    @media (max-width: 720px) {
      text-align: center;
    }
  `,
  buyBtn: css`
    margin-bottom: 20px;
    font-size: 56px;
    width: 100%;
    padding: 23px 6px;
    border-radius: 32px;
    display: none;
    
    @media(max-width: 720px) {
      display: block;
      position: fixed;
      bottom: 10px;
      width: calc(100% - 24px);
      font-size: 17px;
      margin-left: 12px;
      margin-right: 12px;
      border-radius: 16px;
      padding: 10px 6px;
    }
  `,
  mobileAdditionalData: css`
    display: none;
    padding: 12px;
    & .title {
      font-size: 24px;
      line-height: 26px;
      margin-bottom: 12px;
      position: relative;
    }
    
    & .time {
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
      color: #FFFFFF;

      opacity: 0.6;
      margin-bottom: 16px;
      text-transform: uppercase;
    }
    
    & .age {
      font-size: 20px;
      line-height: 26px;
      opacity: 0.6;
      text-transform: uppercase;
      position: absolute;
      top: 0;
      right: 0;
      
    }
    @media(max-width: 720px) {
      display: block;
    }
  `,
  mobileMovieTrailers: css`
    display: none;
    
    video {
      border-radius: 32px;
    }
    @media(max-width: 720px) {
      display: block;
    }
  `,
  info: css`

    font-size: 28px;
    line-height: 100%;
    text-align: center;
    background-color: rgb(19, 32, 72);
    border-radius: 32px;
    margin-bottom: 32px;
    padding-top: 36px;
    padding-bottom: 36px;
    padding-left: 8px;
    padding-right: 8px;


    @media (max-width: 720px) {
      font-size: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  `,
  link: css`
    display: block;
    text-align: center;
    line-height: 100%;
  `
}
