import React, { useRef } from 'react';
import { Carousel as AntdCarousel} from 'antd';

import { styles } from './Carousel.styles';
import { ArrowRightOutlined } from '@ant-design/icons';

// import left from './left.svg';
// import right from './right.svg';

export const Carousel = ({ items }) => {
  const sliderEl = useRef(null);
  return (
    <AntdCarousel
      ref={sliderEl}
      autoplay={true}
      autoplaySpeed={3000}
      className={styles.carousel}
      dots={{ className: "test" }}
      appendDots={(dots) => (
        <ul>
          {dots}
          {/*<div className={styles.contolsBlock}>*/}
          {/*  <img src={left} onClick={() => sliderEl.current?.prev()} alt="" />*/}
          {/*  <img src={right} onClick={() => sliderEl.current?.next()} alt="" />*/}
          {/*</div>*/}
        </ul>
      )}
    >
      {items.map((item, index) => (
        <div key={index} className={styles.wrapper}>
          {item.media.item && <img srcSet={item.media.item.srcSet} alt={item.caption} className={styles.image} />}
          <div className={styles.contentBlock}>
            <div className={styles.text}>{item.description}</div>
            {item.label && <div onClick={() => window.location.href = item.url } className={styles.btn}>
              {item.label}
              <ArrowRightOutlined />
            </div>}
          </div>
        </div>
      ))}
    </AntdCarousel>
  )
}
