import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { cx } from '@emotion/css';
import { ArrowRightOutlined } from '@ant-design/icons';
import { YMaps, Map, Placemark } from "react-yandex-maps";

import { $contacts, getContactsFx, getContactsEvent } from '../../effector/contacts';
import { Layout } from '../../components/Layout';

import { styles } from './Contacts.styles';
import { $isTablet } from '../../effector/main';

export const Contacts = () => {
  useEffect(() => {
    getContactsEvent();
  }, [])

  const data = useStore($contacts);
  const isTablet = useStore($isTablet);
  const loading = useStore(getContactsFx.pending);

  const onClick = () => {
    window.location.href = 'https://yandex.ru/maps/213/moscow/house/bersenevskaya_naberezhnaya_14s5a/Z04YcAdpTEAOQFtvfXt1c35nYQ==/?ll=37.609559%2C55.742245&utm_source=main_stripe_big&z=17.23';
  }
  return (
    <Layout loading={loading}>
      <div className={styles.title}>контакты</div>

      <div className={cx(styles.infoRow,styles.infoRowReverse)}>
        <div onClick={onClick} className={cx(styles.btn, styles.mobileBtn)}>
          На большую карту
          <ArrowRightOutlined />
        </div>
        <div className={styles.infoRowImage}>
          <YMaps>
            <Map defaultState={{ center: [55.742245, 37.609559], zoom: 15 }} height={isTablet ? 400 : 500} width={isTablet ? '100%' : 500} >
              <Placemark geometry={[55.742245, 37.609559]} />
            </Map>
          </YMaps>
        </div>
        <div className={styles.infoRowText}>
          <div className={styles.infoRowTextTitle}>Берсеневская набережная, д. 14, стр. 5а.</div>
          <div onClick={onClick} className={styles.btn}>
            На большую карту
            <ArrowRightOutlined />
          </div>
          <div className={styles.itemsBlock}>
            {(data.items || []).map((contact) => (
              <div key={contact.email} className={styles.item}>
                <div className={styles.titleItem}>{contact.description}</div>
                <a className={styles.titleLink} href={`mailto:${contact.email}`}>{contact.email}</a>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.mobileItemsBlock}>
        {(data.items || []).map((contact) => (
          <div key={contact.email} className={styles.item}>
            <div className={styles.titleItem}>{contact.description}</div>
            <a className={styles.titleLink} href={`mailto:${contact.email}`}>{contact.email}</a>
          </div>
        ))}
      </div>
    </Layout>
  )
}
