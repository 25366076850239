import { css } from '@emotion/css';

export const styles = {
  title: css`
    text-transform: uppercase;
    font-size: 32px;
    text-align: center;
    margin-bottom: 24px;
  `,
  block: css`
    padding-bottom: 40px;
  `,
  wrapper: css`
    padding-left: 24px;
    padding-right: 24px;
    
    @media(max-width: 720px) {
      padding-right: 12px;
      padding-left: 12px;
    }
  `,
  blockTitle: css`
    text-transform: uppercase;
    margin-top: 20px;
    padding-bottom: 15px;
    font-size: 36px;
    line-height: 110%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.28);
    
    @media(max-width: 720px) {
      border-bottom: 2px solid #FFFFFF;
      font-size: 17px;
      padding-bottom: 10px;
      opacity: 0.6;

    } 
  `,
}
