import React, { useEffect }  from 'react';
import { useStore } from 'effector-react';
import { Layout } from '../../components/Layout';
import { useParams } from 'react-router-dom';
import { getProgramDetailsEvent, $programDetails, getProgramDetailsFx } from '../../effector/program';

import { styles } from './ProgramDetails.styles';
import { FilmCard } from '../../components/FilmCard';

export const ProgramDetails = () => {
  const { slug } = useParams();
  useEffect(() => {
    getProgramDetailsEvent(slug)
  }, [slug])

  const data = useStore($programDetails);
  const loading = useStore(getProgramDetailsFx.pending);


  return (
    <Layout loading={loading}>
      <div className={styles.title}>{data?.event?.title}</div>
      {data?.map((film) => (<FilmCard film={film} key={film.slug} /> ))}
    </Layout>
  )
}
