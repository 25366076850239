import { css } from '@emotion/css';

export const styles = {
  title: css`
    text-transform: uppercase;
    font-size: 32px;
    text-align: center;
    margin-bottom: 24px;
  `,
  programContainer: css`
    padding-left: 24px;
    padding-right: 24px;
    @media (max-width: 720px) {
      padding-left: 0;
      padding-right: 0;
    }
  `,
  program: css`
    display: flex;
    margin-bottom: 24px;
    border: 1px solid #e5e5e5;
    border-radius: 44px;

    @media (max-width: 720px) {
      flex-direction: column-reverse;
      border: none;
      border-radius: 32px;
      background: rgb(29, 42, 79);
    }
  `,
  programInfo: css`
    width: 50%;
    padding: 24px;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 720px) {
      width: 100%;
      padding: 0;
    }
  `,
  programImage: css`
    width: 50%;
    border-radius: 44px;
    object-fit: contain;
    @media (max-width: 720px) {
      width: 70%;
      align-self: center;
      border-radius: 32px;
    }
    @media (max-width: 520px) {
      width: 100%;
      align-self: center;
    }
  `,
  programType: css`
    opacity: 0.6;
    font-size: 36px;
    line-height: 100%;
    margin-bottom: 32px;

    @media (max-width: 720px) {
      display: none;
    }
  `,
  programTitle: css`
    font-size: 64px;
    line-height: 100%;
    margin-bottom: 20px;
    @media (max-width: 720px) {
      padding-left: 12px;
      padding-right: 12px;
      font-size: 24px;
      line-height: 110%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  `,
  programCount: css`
    font-size: 28px;
    opacity: 0.6;
    margin-bottom: 20px;
    @media (max-width: 720px) {
      padding-left: 12px;
      padding-right: 12px;
      font-size: 20px;
      line-height: 110%;
      margin-bottom: 20px;
    }
  `,
  btn: css`
    width: 345px;
    background: #FFFFFF;
    border: 1px solid #00123F;
    border-radius: 10px;
    font-size: 28px;
    color: #00123F;
    padding: 9px 6px;
    cursor: pointer;

    &:hover {
      background: #00123F;
      border: 1px solid #FFFFFF;
      color: #FFFFFF;
    }

    &:disabled {
      cursor: not-allowed;
    }
    @media (max-width: 720px) {
      font-size: 17px;
      line-height: 20px;
      margin-left: 12px;
      margin-right: 12px;
      width: calc(100% - 24px);
      border-radius: 16px;
      padding: 12px 6px;
      margin-bottom: 16px;
      
    }
  `,
}
