import React, { useEffect }  from 'react';
import { useStore } from 'effector-react';
import dayjs from 'dayjs';

import { Layout } from '../../components/Layout';
import {
  $scheduleData,
  getScheduleEvent,
} from '../../effector/schedule';

import { styles } from '../ProgramDetails/ProgramDetails.styles';
import { NearFilmsTable } from '../../components/NearFilmsTable';

export const Schedule = () => {
  useEffect(() => {
    getScheduleEvent();
  }, [])

  const schedule = useStore($scheduleData);

  console.log('schedule', schedule)

  const scheduleData = (schedule || []).reduce((result, s) => {
    s.showtimes.forEach((schedule) => {
      result.push({
        ramblerAfishaId: schedule.ramblerAfishaId,
        datetime: schedule.datetime,
        isSaleAvailable: schedule.isSaleAvailable,
        event: {
          slug: s.slug,
          title: s.title,
          ageRestriction: s.ageRestriction,
          director: s.director,
          type: s.type,
          buyUrl: s.buyUrl
        }
      })
    })

    return result;
  }, []).sort((a, b) => (dayjs(a.datetime).isAfter(dayjs(b.datetime)) ? 1 : -1))

  return (
    <Layout>
      <div className={styles.title}>расписание</div>
      {/*<div className={styles.schedule}>*/}
      {/*  <div*/}
      {/*    className={cx(styles.scheduleItem, { [styles.scheduleItemActive]: filter === null })}*/}
      {/*    onClick={() => setScheduleFilter(null)}*/}
      {/*  >*/}
      {/*    все расписание*/}
      {/*  </div>*/}
      {/*  <div*/}
      {/*    className={cx(styles.scheduleItem, { [styles.scheduleItemActive]: filter === cuurentMonth })}*/}
      {/*    onClick={() => setScheduleFilter(cuurentMonth)}*/}

      {/*  >*/}
      {/*    {dayjs().month(cuurentMonth).format('MMMM')}*/}
      {/*  </div>*/}
      {/*  <div*/}
      {/*    className={cx(styles.scheduleItem, { [styles.scheduleItemActive]: filter === cuurentMonth + 1 })}*/}
      {/*    onClick={() => setScheduleFilter(cuurentMonth + 1)}*/}

      {/*  >*/}
      {/*    {dayjs().month(cuurentMonth + 1).format('MMMM')}*/}
      {/*  </div>*/}
      {/*  <div*/}
      {/*    className={cx(styles.scheduleItem, { [styles.scheduleItemActive]: filter === cuurentMonth + 2 })}*/}
      {/*    onClick={() => setScheduleFilter(cuurentMonth + 2)}*/}
      {/*  >*/}
      {/*    {dayjs().month(cuurentMonth + 2).format('MMMM')}*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*{!data.length && <div className={scheduleStyles.emptyRow}>Фильмы отсутствуют</div>}*/}
      {/*{data.map((film) => <FilmCard key={film.slug} film={film} />)}*/}
      <NearFilmsTable items={scheduleData || []} withoutFull />
    </Layout>
  )
}
