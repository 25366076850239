import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { $faqData, getFaqEvent, getFaqFx } from '../../effector/faq';
import { Layout } from '../../components/Layout';
import { ExpandableItem } from '../../components/ExpandableItem';

import { styles } from './Faq.styles';
import { renderHTMLContent } from '../../utils';

export const Faq = () => {
  useEffect(() => {
    getFaqEvent();
  }, [])

  const data = useStore($faqData);
  const loading = useStore(getFaqFx.pending);
  return (
    <Layout loading={loading}>
      <div className={styles.title}>faq</div>
      <div className={styles.wrapper}>
        {data.results?.map((item) => (
          <div key={item.title} className={styles.block}>
            <div className={styles.blockTitle}>{item.title}</div>
            {item.items.map((f, index) => (
              <ExpandableItem title={f.title} key={f.title} index={`${f.title}-${index}`}>
                {renderHTMLContent(f.content)}
              </ExpandableItem>
            ))}
          </div>
        ))}
      </div>
    </Layout>
  )
}
