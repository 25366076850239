import { forward } from 'effector';

import { $programDetails, getProgramDetailsEvent, getProgramDetailsFx  } from './index';
import { getProgramDetails } from '../../requests'

forward({
  from: getProgramDetailsEvent,
  to: getProgramDetailsFx
})

$programDetails.on(getProgramDetailsFx.doneData, (_, data) => data)

getProgramDetailsFx.use((slug) => {
  return getProgramDetails(slug);
})
