import { css } from '@emotion/css';

export const styles = {
  filmRow: css`
    display: flex;
    margin-bottom: 24px;
    //background-color: rgb(19, 32, 72);
    @media (max-width: 720px) {
      flex-direction: column;
      border-bottom: 2px solid white;
    }
  `,
  imageWrap: css`
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 720px) {
      width: 100%;
    }
  `,
  filmImage: css`
    width: 100%;
    border-radius: 44px;
    max-width: 570px;
    max-height: 570px;
  `,
  filmData: css`
    position: relative;
    width: 60%;
    padding: 24px;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 720px) {
      width: 100%;
      padding: 12px;
    }
  `,
  btn: css`
    text-transform: uppercase;
    background: #00123F;
    border: 1px solid #ffffff;
    border-radius: 10px;
    font-size: 28px;
    padding: 9px 40px;
    color: #ffffff;
    cursor: pointer;

    &:hover {
      background: #ffffff;
      border: 1px solid #00123F;
      color: #00123F;
    }

    &:disabled {
      cursor: not-allowed;
    }

    @media (max-width: 720px) {
      text-transform: capitalize;
      border: none;
      color: #FFFFFF;
      font-size: 17px;
      padding: 0;
      width: fit-content;
      text-decoration: underline;
      margin-bottom: 24px;
      &:hover {
        background: transparent;
        border: none;
        color: #FFFFFF;
      }
    }
  `,
  btnGroup: css`
    @media (max-width: 720px) {
      display: flex;
      flex-direction: column-reverse;
      order: 4;
    }
  `,
  linkBtn: css`
    display: inline-block;
    width: fit-content;
    min-width: 252px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    padding: 9px 16px;
    border-radius: 10px;
    font-size: 28px;
    cursor: pointer;
    margin-right: 36px;
    margin-bottom: 36px;
    border: 1px solid #00123F;
    color: #00123F;
    background: white;

    @media (max-width: 720px) {
      width: 100%;
      font-size: 20px;
      line-height: 20px;
      padding: 13px 16px;
      margin-bottom: 20px;
      color: white;
      background: #00123F;
      border: 1px solid white;

      &:not(:disabled):hover {
        color: #00123F;
        background: white;
        border: 1px solid #00123F;
      }
    }
  `,
  buyBtn: css`
    font-size: 28px;
    padding: 9px 16px;
    margin-right: 36px;
    margin-bottom: 36px;
    @media (max-width: 720px) {
      width: 100%;
      font-size: 20px;
      line-height: 20px;
      padding: 13px 16px;

      color: white;
      background: #00123F;
      border: 1px solid white;
      margin-bottom: 20px;
      
      &:not(:disabled):hover {
        color: #00123F;
        background: white;
        border: 1px solid #00123F;
        
      }
    }
  `,
  ageRestriction: css`
    font-size: 18px;
    margin-bottom: 14px;
    border: 1px solid #FFFFFF;
    border-radius: 16px;
    padding: 2px 10px;
    width: fit-content;
    line-height: 100%;

    @media (max-width: 720px) {
      font-size: 20px;
      position: absolute;
      color: rgba(255, 255, 255, 0.6);
      top: 16px;
      right: 12px;
      border: none;
      padding: 0;
    }
  `,
  filmTitle: css`
    font-size: 56px;
    margin-bottom: 20px;
    line-height: 100%;
    @media (max-width: 720px) {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 10px;
      order: 2;
    }
  `,
  filmLabel: css`
    font-weight: 400;
    font-size: 36px;
    letter-spacing: 0.005em;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 44px;
    line-height: 100%;

    @media (max-width: 720px) {
      font-size: 17px;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.6);
      margin-bottom: 10px;
      order: 3;
    }
  `,
  filmTime: css`
    text-transform: uppercase;
    margin-bottom: 44px;
    font-size: 36px;
    line-height: 100%;
    
    div {
      margin-bottom: 28px;
    }
    
    @media (max-width: 720px) {
      font-size: 20px;
      color: rgba(255, 255, 255, 0.6);
      order: 1;
      margin-bottom: 0;
      margin-top: 5px;
      div {
        margin-bottom: 16px;
      }
    }
  `
}
