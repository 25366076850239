import { css } from '@emotion/css';

export const styles = {
  wrapper: css`
    overflow: hidden;
    background: #00123F;
    min-width: 100vw;
    min-height: 100vh;
  `,
  container: css`
    color: white;
    font-size: 14px;
    max-width: 1440px;
    margin: auto;
  `,
}
