import { css } from '@emotion/css';

export const styles = {
  title: css`
    font-size: 36px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 20px;

    @media (max-width: 720px) {
      font-size: 24px;
    }

    @media (max-width: 320px) {
      font-size: 32px;
    }
  `,
}
