import React from 'react';
import { cx } from '@emotion/css';
import { useNavigate } from 'react-router-dom';
import { formatterDate } from '../../utils';
import { BuyBtn } from '../BuyBtn';
import { styles } from './FilmCard.styles';

export const FilmCard = ({ film }) => {
  const navigate = useNavigate();
  const shotTime = film.showtimes?.[0];
  const isMovie = film.type === 'MOVIE';
  return (
    <div key={film.title} className={styles.filmRow}>
      <div className={styles.imageWrap} >
        <img srcSet={film.cover.srcSet} alt={film.title} className={styles.filmImage}/>
      </div>
      <div className={styles.filmData}>
        <div className={styles.ageRestriction}>{film.ageRestriction}+</div>
        <div className={styles.filmTitle}>{film.title}</div>
        {film.label && <div className={styles.filmLabel}>{film.label}</div>}

        {/*{!!film.showtimes.length && isMovie && <div className={styles.filmTime}>*/}
        {/*  {film.showtimes.map((time) => (<div key={time.datetime}>{formatterDate(time.datetime)}</div>))}*/}
        {/*</div>}*/}

        {!isMovie && <div className={styles.filmTime}>
          <div>{formatterDate(film.datetimeStart)}</div>
        </div>}

        <div className={styles.btnGroup}>
          {!!shotTime && isMovie && <BuyBtn ramblerAfishaId={shotTime.ramblerAfishaId} isSaleAvailable={shotTime.isSaleAvailable} className={styles.buyBtn} />}
          {!isMovie && film.buyUrl && <a className={cx(styles.linkBtn)} href={film.buyUrl}>{film.buyButtonLabel}</a>}
          <button className={styles.btn} onClick={() => navigate(`/movies/${film.slug}`)}>подробнее</button>
        </div>
      </div>
    </div>
  );
}
