import { css } from '@emotion/css';

export const styles = {
  btn: css`
    background: #FFFFFF;
    border: 1px solid #00123F;
    border-radius: 10px;
    font-size: 18px;
    color: #00123F;
    padding: 5px 6px;
    cursor: pointer;

    &:not(:disabled):hover {
      background: #00123F;
      border: 1px solid #FFFFFF;
      color: #FFFFFF;
    }

    &:disabled {
      cursor: not-allowed;
    }
  `,
}
