import { css } from '@emotion/css';

export const styles = {
  carousel: css`
    margin-bottom: 24px;

    & .slick-track {
      display: flex;
      align-items: center;
    }
    & .test button {
      width: 16px !important;
      height: 16px !important;
      border-radius: 50% !important;
      background: rgba(255, 255, 255, 0.24) !important;
      border: 2px solid rgba(255, 255, 255, 0.24);
    }
    
    & .test .slick-active button {
      background: rgba(255, 255, 255, 0.24) !important;
      border: 2px solid #FFFFFF;
    }
    & .test .slick-active {
      width: 16px !important;
    }
  `,
  wrapper: css`
    max-height: 640px;
    position: relative;
    border-radius: 100px;
  `,
  contentBlock: css`
    position: absolute;
    left: 50px;
    bottom: 70px;
    max-width: calc(100% - 280px);

    @media (max-width: 900px) {
      max-width: calc(100% - 180px);
    }

    @media (max-width: 500px) {
      max-width: calc(100% - 80px);
      left: 20px;
      bottom: 20px;
    }
  `,
  text: css`
    font-size: 56px;
    color: white;
    line-height: 56px;
    margin-bottom: 20px;

    @media (max-width: 1000px) {
      font-size: 42px;
      line-height: 42px;
    }

    @media (max-width: 780px) {
      font-size: 24px;
      line-height: 25px;
    }
    @media (max-width: 500px) {
      font-size: 18px;
      line-height: 25px;
    }
  `,
  image: css`
    width: 100%;
    height: 100%;
    margin: auto;
    border-radius: 100px;
  `,
  btn: css`
    cursor: pointer;
    width: fit-content;
    background: transparent;
    color: white;
    border: 1px solid #FFFFFF;
    border-radius: 16px;
    font-size: 28px;
    padding: 9px 20px;
    display: flex;
    align-items: center;
    
    & > span {
      margin-left: 10px;
    }

    @media (max-width: 780px) {
      font-size: 24px;
    }
  `,
  contolsBlock: css`
    position: absolute;
    right: -150px;
    bottom: 31px;
    display: flex;
    color: white;
    
    img {
      margin-left: 50px;
      margin-right: 50px;
      cursor: pointer;
    }


    @media (max-width: 780px) {
      right: -50px;
      img {
        margin-left: 30px;
        margin-right: 30px;
        height: 48px;
      }
    }
  `
}
