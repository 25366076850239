import { css } from '@emotion/css';

export const styles = {
  wrapper: css`
    width: 100vw;
    background: white;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    color: #00123F;

    @media (max-width: 600px) {
      color: white;
      background: #00123F;
    }
  `,
  subWrapper: css`
    max-width: 1440px;
    margin: auto;
  `,
  row: css`
    position: relative;
    display: flex;
    
    & > div {
      border: 1px solid #00123F;
      padding: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;
    }
    
    & > div:nth-child(1) {
      width: 300px;
      flex-shrink: 0;
      text-transform: uppercase;
      padding: 60px 80px;
      white-space: nowrap;
    }

    & > div:nth-child(2) {
      width: 30%;
      flex-shrink: 0;
      text-transform: uppercase;
      flex-direction: column;
      align-items: center;
      
      a {
        color: #00123F;
        text-align: center;
      }
    }

    & > div:nth-child(3) {
      display: block;
      flex-grow: 1;
    }

    & > div:nth-child(4) {
      width: 300px;
      flex-shrink: 0;
      padding: 0;
      button, a {
        font-size: 28px;
        border-radius: 16px;
        padding: 9px 35px;
      }
    }

    @media (max-width: 1200px) {
      & > div:nth-child(1) {
        width: auto;
        padding: 24px;
      }

      & > div:nth-child(4) {
        width: auto;
        padding: 24px;
      }
    }

    @media (max-width: 1000px) {
      & > div {
        font-size: 20px;
      }
    }
    @media (max-width: 900px) {
      & > div:nth-child(4) {
        
        button, a {
          font-size: 20px;
          padding: 9px 20px;
        }
      }
    }

    @media (max-width: 780px) {
      & > div:nth-child(1) {
        padding: 16px;
        white-space: normal;
      }

      & > div:nth-child(2) {
        padding: 16px;
      }

      & > div:nth-child(3) {
        padding: 16px;
      }

      & > div:nth-child(4) {
        padding: 16px;
      }
    }
    
    @media (max-width: 700px) {
      & > div {
        font-size: 16px;
      }    
    }

      @media (max-width: 600px) {
      flex-direction: column;
      border-bottom: 1px solid #00123F;
      padding-bottom: 15px;
      & > div {
        border: none;
        padding: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      & > div:nth-child(1) {
        width: 100%;
      }

      & > div:nth-child(2) {
        width: 100%;
        flex-shrink: 0;
        text-transform: uppercase;
        font-size: 18px;
      }

      & > div:nth-child(4) {
        width: 100%;
        flex-shrink: 0;
        padding: 0;
        button, a {
          width: 70%;
          font-size: 16px;
          height: 40px;
        }
      }
    }

    @media (max-width: 600px) {
      border-top: 2px solid white;
      border-bottom: 2px solid white;
      margin-left: 12px;
      margin-right: 12px;

      & > div:nth-child(1) {
        padding-top: 16px;
        display: flex;
        justify-content: left;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.6);
        font-size: 20px;
      }
      
      & > div:nth-child(2) {
        display: flex;
        justify-content: left;
        a {
          color: white;
          font-size: 28px;
        }

        & > span {
          position: absolute;
          top: 16px;
          right: 10px;
          color: rgba(255, 255, 255, 0.6);
          font-size: 20px;
        }
      }
      & > div:nth-child(3) {
        font-size: 17px;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.6);
      }
      
      & > div:nth-child(4) {
        width: 100%;

        button, a {
          margin-top: 12px;
          width: 100%;
          font-size: 20px;
          height: 48px;
          color: white;
          background: #00123F;
          border: 1px solid white;
          
          &:hover {
            color: #00123F;
            background: white;
          }
        }
      }
    }

  `,
  btn: css`
    cursor: pointer;
    width: 100%;
    background: white;
    color: #00123F;
    font-size: 32px;
    padding: 34px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid white;

    & > span {
      margin-left: 10px;
      color: #00123F;
    }
    
    &:hover {
      background: #00123F;
      color: white;
      border: 1px solid #00123F;
      & > span {
        color: white;
      }
    }
    

    @media (max-width: 600px) {
      margin-right: 12px;
      margin-left: 12px;
      border-radius: 16px;
      width: auto;
      margin-top: 16px;
      margin-bottom: 24px;
      font-size: 20px;
      &:hover {
        border: 1px solid white;
      }
    }
  `,
}
