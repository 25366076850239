import { forward } from 'effector';

import { $contacts, getContactsFx, getContactsEvent  } from './index';
import { getContacts } from '../../requests'

forward({
  from: getContactsEvent,
  to: getContactsFx
})

$contacts.on(getContactsFx.doneData, (_, data) => data)

getContactsFx.use(() => {
  return getContacts();
})
