import { css } from '@emotion/css';

export const styles = {
  wrapper: css`
    min-height: 250px;
    background-size: cover;
    background-position: center;
    padding: 20px;
    padding-bottom: 100px;
  `,
  mainPartner: css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      font-size: 24px;
      line-height: 110%;
      margin-bottom: 44px;
    }
    
    img {
      margin-bottom: 15px;
      max-width: 600px;
      width: 100%;
      cursor: pointer;
    }
    
    @media (max-width:720px) {
      span {
        font-size: 20px;
        line-height: 120%;
      }
    }
  `,
  mainPartnerAbout: css`
    @media (max-width:720px) {
      span {
        font-size: 20px;
        line-height: 120%;
        order: 3;
      }

      img {
        order: 2;
      }
    }
  `,
  partners: css`
    margin-top: 20px;
    margin-bottom: 50px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    div {
      font-size: 32px;
      margin-bottom: 10px;
      color: rgba(255, 255, 255, 0.4);
    }
  `,
  partner: css`
    img {
      display: inline-block;
      max-width: 250px;
      max-height: 200px;
      margin: 5px;
      cursor: pointer;
    }
  `,
  partnerRole: css`
    img {
      display: inline-block;
      max-width: 250px;
      max-height: 200px;
      margin: 5px;
      cursor: pointer;
    }
  `,
  partnerImg: css`
    max-width: 350px !important;
    max-height: 300px !important;
  `,
  titlePartner: css`
    font-size: 32px;
    margin-bottom: 24px;
    font-weight: 400;
    line-height: 120%;
    color: rgba(255, 255, 255, 0.4);
  `,
  titlePartnerAbout: css`
    text-transform: uppercase;
    font-size: 36px;
    color: white;
    margin-top: 64px;
    
    @media(max-width: 720px) {
      font-size: 20px;
      margin-top: 0;
      order: 1;
    }
  `,
}
