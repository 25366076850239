import { forward } from 'effector';

import { $movieDetails, getMovieDetailsEvent, getMovieDetailsFx  } from './index';
import { getMovieDetails } from '../../requests'

forward({
  from: getMovieDetailsEvent,
  to: getMovieDetailsFx
})

$movieDetails.on(getMovieDetailsFx.doneData, (_, data) => data)

getMovieDetailsFx.use((slug) => getMovieDetails(slug))
