import { css } from '@emotion/css';

export const styles = {
  title: css`
    text-transform: uppercase;
    font-size: 32px;
    text-align: center;
    margin-bottom: 24px;
  `,
  infoRow: css`
    display: flex;
    margin-bottom: 24px;
    background-color: rgb(19, 32, 72);
    border-radius: 100px;
    @media (max-width: 720px) {
      flex-direction: column;
      border-radius: 32px;
    }
  `,
  infoRowReverse: css`
    @media (min-width: 720px) {
      flex-direction: row-reverse;
    }
  `,
  infoRowText: css`
    width: 50%;
    padding: 24px;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 720px) {
      width: 100%;
      font-size: 14px;
      padding: 12px;
    }
  `,
  infoRowTextStrelka: css`
    font-size: 40px;
    line-height: 110%;
    @media (max-width: 1100px) {
      font-size: 28px;
    }
    @media (max-width: 600px) {
      font-size: 20px;
    }
  `,
  imageWrap: css`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 720px) {
      width: 100%;
    }
  `,
  infoRowImage: css`
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    border-radius: 100px;
    @media (max-width: 720px) {
      border-radius: 32px;
    }
  `,
  infoRowTextTitle: css`
    font-size: 40px;
    margin-bottom: 15px;
    line-height: 100%;
    @media (max-width: 1000px) {
      font-size: 28px;
    }
  `,
  infoRowTextDescription: css`
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 10px;
    line-height: 120%;
    
    @media (max-width: 1100px) {
      font-size: 20px;
    }

    @media (max-width: 720px) {
      font-size: 20px;
    }
  `,
  infoRowTextLink: css`
    font-size: 32px;
    text-transform: uppercase;
    text-decoration: underline;
    color: white;
    @media (max-width: 1100px) {
      font-size: 24px;
    }
  `,
  mobilePadding: css`
    @media (max-width: 720px) {
      padding-bottom: 50px;
    }
  `,
}
