import React  from 'react';
import { useStore } from 'effector-react';
import { Layout } from '../../components/Layout';
import { styles } from './Programs.styles';
import { $mainData } from '../../effector/main';
import { useNavigate } from 'react-router-dom';
const plural = require('plural-ru');


const programTypes = {
  "EVENT": 'Программа'
}
export const Programs = () => {

  const data = useStore($mainData);
  const navigate = useNavigate();

  return (
    <Layout>
      <div className={styles.title}>программы и фильмы</div>
      <div className={styles.programContainer}>
        {(data.events || []).map((program) => (
          <div key={program.title} className={styles.program}>
            <div className={styles.programInfo}>
              <div className={styles.programType}>{programTypes[program.type] || program.type}</div>
              <div className={styles.programTitle}>{program.title}</div>
              <div className={styles.programCount}>{plural(program.childrenCount, '%d фильм', '%d фильма', '%d фильмов')}</div>
              <button className={styles.btn} onClick={() => navigate(`/programs/${program.slug}`)}>Смотреть фильмы</button>
            </div>
            <img className={styles.programImage} srcSet={program.cover.srcSet} alt={program.title}/>
          </div>
        ))}
      </div>
    </Layout>
  )
}
