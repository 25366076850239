import React  from 'react';
import { useStore } from 'effector-react';
import { Layout } from '../../components/Layout';
import { Carousel } from '../../components/Carousel';
import { NearFilmsTable } from '../../components/NearFilmsTable';
import { $isMobile, $mainData, getMainDataFx } from '../../effector/main';

import { styles } from './Main.styles';

export const Main = () => {

  const data = useStore($mainData);
  const loading = useStore(getMainDataFx.pending);
  const isMobile = useStore($isMobile);
  return (
    <Layout loading={loading}>
      {!isMobile && <Carousel items={data?.history?.gallery || []} />}
      <div className={styles.title}>{isMobile ? 'Расписание' : 'Расписание ближайших показов'}</div>
      <NearFilmsTable items={data?.showtimes || []} />
    </Layout>
  )
}
