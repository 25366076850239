import { css } from '@emotion/css';

export const styles = {
  wrapper: css`
    border-bottom: 1px solid rgba(255, 255, 255, 0.28);
  `,
  item: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 12px;
    border-radius: 16px;
    cursor: pointer;
    font-size: 44px;
    margin-left: -12px;
    margin-right: -12px;
    
    @media(max-width: 720px) {
      font-size: 17px;
    }
  `,
  itemWithHover: css`

    &:hover {
      background: #EA346B;
    }
  `,
  expanded: css`
    background: #EA346B;
  `,
  itemImage: css`
    width: 64px;
    height: 64px;
    
    @media(max-width: 720px) {
      width: 28px;
      height: 28px;
    }
  `,
  itemContent: css`
    padding-top: 32px;
    padding-bottom: 32px;
    padding-right: 30px;
    padding-left: 30px;
    font-size: 32px;
    @media(max-width: 720px) {
      font-size: 17px;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-right: 15px;
      padding-left: 15px;
    }
  `
}
