import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from 'effector-react';
import { Carousel as AntdCarousel} from 'antd';
import ReactPlayer from 'react-player'
import { cx } from '@emotion/css';
import dayjs from 'dayjs';

import { BuyBtn, LinkBtn } from '../../components/BuyBtn';
import { Layout } from '../../components/Layout';
import { getMovieDetailsEvent, $movieDetails } from '../../effector/movie';
import { formatterDate, generateBuyUrl, renderHTMLContent } from '../../utils';

import clock from './IconClock.svg';
import age from './ageIcon.svg';
import rub from './rub.svg';
import language from './language.svg';
import videoStart from './videoStart.svg';

import { styles } from './MovieDetails.styles';

const humanizeDuration = require("humanize-duration");


export const DetailsItem = (props) => {
  return (
    <div className={styles.additionalItem}>
      <img src={props.icon} alt="" />
      <div className={styles.additionalItemTitle}>{props.title}</div>
      <div className={styles.additionalItemText}>{props.text}</div>
    </div>
  )
}

export const MovieDetails = () => {
  const { slug } = useParams();
  useEffect(() => {
    getMovieDetailsEvent(slug)
  }, [slug])

  const data = useStore($movieDetails);
  const showTime = data.event?.showtimes?.[0];
  const isMovie = data.event?.showtimes?.length

  return (
    <Layout>
      <div className={styles.movieInfoRow}>
        <img srcSet={data.event?.cover.srcSet} alt={data.event?.title} className={styles.movieInfoRowImage} />
        <div className={styles.movieInfoRowInfo}>
          <div className={styles.movieInfoRowTitle}>{data.event?.title}</div>
          {data.event?.label && <div className={styles.movieInfoRowLabel}>{data.event?.label}</div>}
          {data.event?.director && <div className={styles.movieInfoRowDirector}>{data.event?.director}</div>}
          {!!isMovie && data.event?.showtimes.map(s => (
            <div key={s.datetime} className={styles.rowBuyBtn}>
              <div className={styles.movieInfoRowTime}>{formatterDate(s.datetime)}</div>
              {data.event?.buyUrl ? <LinkBtn className={styles.movieInfoRowBtn} url={generateBuyUrl(data.event?.buyUrl, s.datetime, slug)} text={data.event?.buyButtonLabel} /> :
              <BuyBtn className={styles.movieInfoRowBtn} isSaleAvailable={s?.isSaleAvailable} ramblerAfishaId={s?.ramblerAfishaId} />}
            </div>
          ))}
          {!isMovie && <div className={styles.movieInfoRowTime}>{formatterDate(data.event?.datetimeStart)}</div>}
          {/*{isMovie && <BuyBtn className={styles.movieInfoRowBtn} isSaleAvailable={showTime?.isSaleAvailable} ramblerAfishaId={showTime?.ramblerAfishaId} />}*/}
          {/*{!isMovie && <LinkBtn className={styles.movieInfoRowBtn} url={data.event?.buyUrl} text={data.event?.buyButtonLabel} />}*/}
        </div>
      </div>
      <div className={styles.mobileAdditionalData}>
        <div className="title">
          Дата показа
          {data.event?.ageRestriction && <div className="age">{`${data.event?.ageRestriction}+`}</div>}
        </div>
        {data.event?.showtimes.map(s => <div key={s.datetime} className="time">{formatterDate(s.datetime)}</div>)}
        {!isMovie && <div className="time">{formatterDate(data.event?.datetimeStart)}</div>}
      </div>
      <div className={styles.movieDescriptionRow}>
        <div className={styles.movieDescription}>
          <div className={styles.movieDescriptionTitle}>Описание</div>
          <div className={styles.mobileMovieTrailers}>
            <AntdCarousel>
              {data.event?.trailers.map(trailer => (
                <ReactPlayer url={trailer.src} key={trailer.src} controls width="none" height="none" />
              ))}
            </AntdCarousel>
          </div>
          <div className={styles.movieDescriptionText}>{renderHTMLContent(data.event?.description || [])}</div>
        </div>
        <div className={styles.movieTrailer}>
          <AntdCarousel>
            {data.event?.trailers.map(trailer => (
              <ReactPlayer url={trailer.src} key={trailer.src} controls width="none" height="none" />
            ))}
          </AntdCarousel>
        </div>
      </div>
      <div className={styles.additionalBlock}>
        {!!data.event?.duration && <DetailsItem icon={clock} title={'Продолжительность'} text={humanizeDuration(data.event?.duration * 60 * 1000, { language: "ru" })} /> }
        {!!data.event?.ageRestriction && <DetailsItem icon={age} title={'Возрастное ограничение'} text={`${data.event?.ageRestriction}+`} />}
        {!!data.event?.price && <DetailsItem icon={rub} title={'Цена билета'} text={data.event?.price} />}
        {!!data.event?.language && <DetailsItem icon={language} title={'Язык'} text={data.event?.language} />}
        {!!showTime && <DetailsItem icon={videoStart} title={'Начало показа'} text={dayjs(showTime.datetime).utcOffset(showTime.datetime.slice(-6)).format('HH:mm')} />}
      </div>
      <div className={cx(styles.info)}>ПОЖАЛУЙСТА, ПРИХОДИТЕ ЗАРАНЕЕ, ЧТОБЫ ЗАНЯТЬ УДОБНЫЕ МЕСТА</div>
      {/*{isMovie && <BuyBtn className={styles.buyBtn} isSaleAvailable={showTime?.isSaleAvailable} ramblerAfishaId={showTime?.ramblerAfishaId} />}*/}
      {data.event?.buyUrl ? <LinkBtn className={cx(styles.buyBtn, styles.link)} url={generateBuyUrl(data.event?.buyUrl, showTime?.datetime, slug)} text={data.event?.buyButtonLabel}  /> :
        <BuyBtn className={styles.buyBtn} isSaleAvailable={showTime?.isSaleAvailable} ramblerAfishaId={showTime?.ramblerAfishaId} />}
    </Layout>

  )
}
