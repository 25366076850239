import axios from 'axios';

export const getHomePageData = () => axios.get('https://summercinema.ru/api/v1/home?lang=ru').then(({ data }) => data);

export const getMovieDetails = (slug) => axios.get(`https://summercinema.ru/api/v1/events/${slug}?lang=ru`).then(({ data }) => data);

export const getProgramDetails = (slug) => axios.get(`https://summercinema.ru/api/v1/events`).then(({ data }) => data);

export const getSchedule = (start_date, end_date) => axios.get(`https://summercinema.ru/api/v1/schedule?start_date=${start_date}&end_date=${end_date}&lang=ru`).then(({ data }) => data);

export const getFaq = () => axios.get(`https://summercinema.ru/api/v1/faq?lang=ru`).then(({ data }) => data);

export const getContacts = () => axios.get(`https://summercinema.ru/api/v1/contacts?lang=ru`).then(({ data }) => data);
