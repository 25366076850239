import dayjs from 'dayjs';
import React, { createElement } from 'react';
import { css } from '@emotion/css';

export const formatterDate = (dateStr) => {
  if (!dateStr) {
    return '';
  }
  const date = dayjs(dateStr);
  if (date.isToday()) {
    return `Сегодня · ${ date.format('HH:mm')}`;
  } else if (date.isTomorrow()) {
    return `Завтра · ${ date.format('HH:mm')}`;
  } else {
    return date.utcOffset(dateStr.slice(-6)).format('DD MMM · HH:mm')
  }
}

export const renderHTMLContent = (content) => {
  const style = css`padding-bottom: 8px; padding-top: 8px;`
  return content.map(({data, type}, index) => {
    if (type === "paragraph") {
      return  <div key={index} className={style} dangerouslySetInnerHTML={{ __html: data.text }} />;
    }

    if (type === 'List') {
      return <ul className={style} key={index}>{data.items.map((item, index) => (<li dangerouslySetInnerHTML={{ __html: item }} key={index} />))}</ul>
    }

    if (type === 'Header') {
      const tag = `h${data.level}`;

      return createElement(tag, {}, data.text);
    }
    return ''
  })
}

export const generateBuyUrl = (url, datetime, slug) => {
  if (!url || url.includes('?') || !url.includes('live.mts.ru')) {
    return url;
  }

  return `${url}?date=${dayjs(datetime).format('YYYY-MM-DD')}&name=venueMetroName&value=Summer+Cinema+by+Kion&utm_source=summercinema.ru&utm_medium=referral&utm_campaign=${slug}`

}
