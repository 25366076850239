import { css } from '@emotion/css';

export const styles = {
  title: css`
    text-transform: uppercase;
    font-size: 32px;
    text-align: center;
    margin-bottom: 24px;
  `,
  infoRow: css`
    display: flex;
    margin-bottom: 24px;
    background-color: rgb(29, 42, 79);
    border: 1.27273px solid #000000;
    border-radius: 100px;
    @media (max-width: 720px) {
      flex-direction: column;
      border-radius: 32px;

    }
  `,
  map: css`
    border-radius: 44px;
  
  `,
  infoRowReverse: css`
    @media (max-width: 720px) {
      flex-direction: column-reverse;
    }
  `,
  infoRowText: css`
    width: 50%;
    padding: 24px;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 720px) {
      width: 100%;
      padding: 0;
    }
  `,
  infoRowImage: css`
    border-radius: 100px;
    overflow: hidden;

    @media (max-width: 720px) {
      border-radius: 32px;

    }
  `,
  infoRowTextTitle: css`
    font-size: 40px;
    line-height: 110%;
    margin-bottom: 24px;
    @media (max-width: 720px) {
      font-size: 20px;
      margin-top: 30px;
      margin-bottom: 16px;
      padding-left: 12px;
      padding-right: 12px;
    }
  `,
  infoRowTextDescription: css`
    font-size: 14px;
    margin-bottom: 10px;
  `,
  btn: css`
    cursor: pointer;
    width: fit-content;
    background: transparent;
    color: white;
    border: 1px solid #FFFFFF;
    border-radius: 16px;
    font-size: 28px;
    padding: 9px 20px;
    display: flex;
    align-items: center;
    margin-bottom: 36px;
    
    & > span {
      margin-left: 10px;
    }

    @media (max-width: 720px) {
      display: none;
    }
  `,
  itemsBlock: css`
    margin-top: 20px;
    @media (max-width: 720px) {
      display: none;
    }
  `,
  item: css`
    margin-bottom: 36px;
  `,
  titleItem: css`
    font-size: 24px;
    margin-bottom: 10px;
    line-height: 100%;
    @media (max-width: 720px) {
      font-size: 24px;
    }
  `,
  titleLink: css`
    color: #EA346B;
    font-size: 24px;
    line-height: 120%;
    text-decoration: underline;
  `,
  mobileBtn: css`
    display: none;
    margin-top: 24px;
    width: calc(100% - 24px);
    margin-left: 12px;
    font-size: 17px;

    margin-right: 12px;
    color: #00123F;
    border: 1px solid #00123F;
    background: white;
    
    &:hover {
      color: white;
      border: 1px solid white;
      background: #00123F;
      
    }
    @media (max-width: 720px) {
      display: flex;
      justify-content: space-between;
    }
  `,
  mobileItemsBlock: css`
    display: none;
    margin-top: 50px;
    padding-left: 12px;
    padding-right: 12px;
    @media (max-width: 720px) {
      display: block;
    }
  
  `
}
