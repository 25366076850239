import React from 'react';
import { cx } from '@emotion/css';

import { styles } from './Partners.styles';
import bg from './bg.svg';
import { useLocation } from 'react-router-dom';

export const Partners = (props) => {
  const mainPartners = props.items.filter(item => item.isMainPartner === true)
  const partners = props.items.filter(item => !item.isMainPartner)
  const { pathname } = useLocation();
  const isAboutPage = pathname === '/about';

  const infoParners = partners
    .filter((item) => item.role !== 'Партнер');
  const mainParners = partners
    .filter((item) => item.role === 'Партнер');

  return (
    <div className={styles.wrapper} style={{ backgroundImage: `url(${bg})` }}>
      <div
        className={cx(styles.mainPartner, {
          [styles.mainPartnerAbout]: isAboutPage,
        })}
      >
        {!!mainPartners.length && mainPartners.length > 1 && (
          <div
            className={cx(styles.titlePartner, {
              [styles.titlePartnerAbout]: isAboutPage,
            })}
          >
            Титульные партнеры
          </div>
        )}
        {!!mainPartners.length && mainPartners.length === 1 && (
          <div
            className={cx(styles.titlePartner, {
              [styles.titlePartnerAbout]: isAboutPage,
            })}
          >
            Титульный партнер
          </div>
        )}
        {isAboutPage && (
          <span>
            Онлайн-кинотеатр KION — часть продуктовой экосистемы МТС. Включает более 200 ТВ-каналов, тысячи фильмов, сериалов, мультфильмов и документальных проектов. Флагманским направлением платформы является линейка контента KION Originals. Среди них — драмеди «Почка», детективная драма «Обоюдное согласие», психологическая криминальная драма «Хрустальный» и другие. За три года работы платформы оригинальные проекты KION получили более 50 российских и международных наград.
          </span>
        )}
        {mainPartners.map((mainPartner) => (
          <img
            srcSet={mainPartner.cover.srcSet}
            key={mainPartner.title}
            alt={mainPartner.title}
            onClick={() => {
              window.location.href = mainPartner.url;
            }}
          />
        ))}
      </div>
      {!isAboutPage && (
        <div className={styles.partners}>
          <div>При поддержке</div>
          <div className={styles.partnerRole}>
            {mainParners.map((item) => (
              <img
                key={item.title}
                alt={item.title}
                className={cx({ [styles.partnerImg]: item.title === 'Forma' })}
                srcSet={item.cover.srcSet}
                onClick={() => {
                  if (item.url) {
                    window.location.href = item.url;
                  }
                }}
              />
            ))}
          </div>
        </div>
      )}
      {!isAboutPage && (
        <div className={styles.partners}>
          {!!infoParners.length && infoParners.length > 1 && (
            <div>Информационные партнеры</div>
          )}
          {!!infoParners.length && infoParners.length === 1 && (
            <div>Информационный партнер</div>
          )}
          <div className={styles.partner}>
            {infoParners.map((item) => (
              <img
                key={item.title}
                alt={item.title}
                srcSet={item.cover.srcSet}
                onClick={() => {
                  window.location.href = item.url;
                }}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
