import React from 'react';
import { cx } from '@emotion/css';

import { styles } from './ExpandableItem.styles';
import IconPlus from './IconPlus.svg';
import IconClose from './IconClose.svg';
import { useStore } from 'effector-react';
import { $openedItem, toggleItem } from '../../effector/faq';
import { $isMobile } from '../../effector/main';

export const ExpandableItem = (props) => {
  const key = useStore($openedItem);
  const isMobile = useStore($isMobile);
  return (
    <div className={styles.wrapper}>
      <div
        className={cx(styles.item, { [styles.itemWithHover]: !isMobile, [styles.expanded]: props.index === key})}
        onClick={() => toggleItem(props.index === key ? '' : props.index)}
      >
        <div>{props.title}</div>
        <img className={styles.itemImage} src={props.index === key ? IconClose : IconPlus} alt="" />
      </div>
      {props.index === key && <div className={styles.itemContent}>{props.children}</div>}
    </div>
  )
}
