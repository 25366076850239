import { css } from '@emotion/css';

export const styles = {
  title: css`
    text-transform: uppercase;
    font-size: 32px;
    text-align: center;
    margin-bottom: 24px;
  `,
  schedule: css`
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    
    @media (max-width: 720px) {
      flex-direction: column;
      align-items: center;
    }
  `,
  scheduleItem: css`
    font-size: 28px;
    margin-left: 24px;
    margin-right: 24px;
    text-transform: uppercase;
    cursor: pointer;
  `,
  scheduleItemActive: css`
    text-decoration: underline;
  `
}
